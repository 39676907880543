<template>
    <div class="nav-block">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>
                <a href="/" target="_self">
                    <img src="/imgs/icon/map.svg"> 首页
                </a>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,i) in linkList" :key="i">
                <a :href="item.link">{{item.title}}</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
    export default {
        name: "mBreadcrumb",
        data() {
            return {}
        },
        props: {
            linkList: {
                type: Array,
                default() {
                    return []
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "@/common/sass/common.scss";

    .nav-block {
        width: 100%;
        margin-bottom: 14px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -14px;
            left: 0;
            width: 240px;
            height: 3px;
            background: $mainColor
        }
    }
</style>