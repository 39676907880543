import utils from "../common/libs/utils";

const baseUrl = "/website/api/";
const commonUrl="/api/";

export default {
    //获取网站设置
    getSetting(params={}){
        return new Promise((resolve)=>{
            utils.get(baseUrl+'setting/get',params).then(data=>{
                resolve(data)
            })
        })
    },
    //获取栏目列表
    getNavList(params={}){
        return new Promise((resolve)=>{
            utils.get(baseUrl+'navColumn/list',params).then(data=>{
                resolve(data)
            })
        })
    },
    //1.获取职位列表
    getJobsList(params = {}) {
        return new Promise((resolve) => {
            utils.get(baseUrl + 'job/page', params).then(data => {
                resolve(data);
            })
        });
    },
    //获取双选会列表
    getJobFairList(params = {}) {
        return new Promise((resolve) => {
            utils.get(baseUrl + 'bothmeet/page', params).then(data => {
                resolve(data);
            })
        });
    },
    //获取近期双选会列表
    getRecentJobFairList(params = {}) {
        return new Promise((resolve) => {
            utils.get(baseUrl + 'bothmeet/getRecentBothmeetList', params).then(data => {
                resolve(data);
            })
        });
    },
    //获取宣讲会列表
    getCareerList(params = {}) {
        return new Promise((resolve) => {
            utils.get(baseUrl + 'career/page', params).then(data => {
                resolve(data);
            })
        });
    },
    //获取新闻资讯
    getNoticeList(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'news/page', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取公司列表
    getCompanyList(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'bothmeet/getJobPage', params).then(data => {
                resolve(data);
            })
        })
    },
    //就业报告
    getReportList(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'get_month_event_day', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取日历招聘会
    getMothEventDay(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'home/getCalendarDay', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取首页信息
    getHomeData(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'home/getData', params).then(data => {
                resolve(data);
            })
        })
    },


    //获取职位详情
    getJobDetail(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'job/get', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取双选会详情
    getJobfairDetail(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'bothmeet/get', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取宣讲会详情
    getCareerDetail(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'career/get', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取通知详情
    getNoticeDetail(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'news/get', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取职位发布院校
    getPublishSchoolList(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'job/getPublishSchoolList', params).then(data => {
                resolve(data);
            })
        })
    },
    //获取公司详情
    getCompanyDetail(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'company/get', params).then(data => {
                resolve(data);
            })
        })
    },
    //根据双选会申请Id获取公司详情
    getCompanyDetailByBothmeetApplyId(params={}){
        return new Promise(resolve=>{
            utils.get(baseUrl+'bothmeet/getCompanyInfoByApplyId',params).then(data=>{
                resolve(data);
            })
        })
    },
    getDictList(params = {}) {
        return new Promise(resolve => {
            utils.get(commonUrl + 'sysDictData/getDictDataDropdown', params).then(data => {
                resolve(data);
            })
        })
    },
    getCalendar(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'home/getCalendar', params).then(data => {
                resolve(data);
            })
        })
    },
    getSchoolList(params = {}) {
        return new Promise(resolve => {
            utils.get(baseUrl + 'school/list', params).then(data => {
                resolve(data);
            })
        })
    }
};


