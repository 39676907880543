<template>
    <div class="news-item" :style="mStyle" @click="clickHandle">
        <p class="news-tip">{{dateFormat(item.publishTime,'YYYY-MM-DD')}}</p>
        <p class="news-tit">
            {{item.title}}
        </p>
    </div>
</template>

<script>
import moment from 'moment'
    export default {
        name: "blockNotice",
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            mStyle: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        methods: {
            dateFormat(date,format){
                return moment(date).format(format);
            },
            clickHandle() {
                this.$emit('click')
            }
        },
        watch:{
            item(val){
                debugger
                console.log(val)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/common/sass/common.scss";

    .news-item {
        width: 330px;
        margin: 10px 25px 10px 0;
        cursor: pointer;

        &:nth-of-type(4),
        &:nth-of-type(8),
        &:nth-of-type(12){
            margin-right: 0;
        }
    }

    .news-item {
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        padding: 20px;
        box-sizing: border-box;
        margin: 20px 25px 0 0;

        &:hover {
            background-color: #f9f9f9;

            .news-tit {
                color: $mainColor;
            }
        }

        .news-tip {
            height: 10px;
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
            color: $mainColor;
        }

        .news-tit {
            margin-top: 14px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
</style>