<template>
  <div class="header" v-loading="loading" element-loading-background="rgba(122, 122, 122, 0.8)">
    <header class="gl-header">
      <div class="header-wrap">
        <div class="content-wrap">
          <el-row type="flex" justify="space-between" class="header-nav">
            <el-col :span="10">
              <div class="icon" style="width: 33.5625rem; height: 3.3125rem; display: flex">
                <a href="https://smartedu.cn" target="_blank">
                  <img src="../assets/global/header3.6b7595a.png" alt="" style="width: 5.125rem; height: 3.3125rem" />
                </a>
                <div class="xian" data-v-28fe217c=""></div>
                <a href="https://hunan.smartedu.cn/" target="_blank">
                  <img src="../assets/global/header2.f67cc05.png" alt="" style="
                      width: 15.1875rem;
                      height: 3.125rem;
                      margin-top: 0.3125rem;
                    " />
                </a>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="text-wrap">
                <i class="iconfont">
                  <img src="/imgs/icon/student.svg" alt="" />
                </i>
                <span><a href="http://student.hunbys.net" target="_blank" style="color:#fff">学生登录</a></span>
                <i class="iconfont">
                  <img src="/imgs/icon/company.svg" alt="" />
                </i>
                <span><a href="http://company.hunbys.net" target="_blank" style="color:#fff">单位登录</a></span>
                <i class="iconfont">
                  <img src="/imgs/icon/school.svg" alt="" />
                </i>
                <span><a href="http://teacher.hunbys.net" target="_blank" style="color:#fff">学校登录</a></span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="header-menu">
        <div class="content-wrap">
          <el-menu :default-active="menuIndex" class="el-menu-wrap" mode="horizontal">
            <el-menu-item v-for="item in menuList" :index="item.index" :key="item.index" @click="goto(item)">
              {{ item.title }}
            </el-menu-item>
          </el-menu>

          <div class="search-input-wrap">
            <input type="text" v-model="keyword" placeholder="请输入搜索关键字" class="search-input" @chnage="goSearch" />
            <img src="/imgs/search-btn.png" class="search-btn" />
          </div>
        </div>
      </div>
    </header>
    <!--轮播图-->

    <section class="swipe-wrap">
      <el-carousel :height="swipeHeight" :arrow="option.arrow" :indicator-position="option.indicatorPosition">
        <el-carousel-item v-for="item in swipeList" :key="item.id">
          <a v-if="item.isExternal" :href="item.url" target="_blank">
            <img :src="item.coverImgUrl" :alt="item.title" class="block-img" />
          </a>
          <a v-else :href="`/notice-detail.html?id=` + item.id + `&key=` + item.navId">
            <img :src="item.coverImgUrl" :alt="item.title" class="block-img" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </section>
  </div>
</template>

<script>
import tool from '@/utils/tool'
export default {
  name: "mHeader",
  data() {
    return {
      keyword: "",
      loading: false,
      menuIndex: "/",
      menuList: [
        { title: "首页", link: "/", index: "1" },
        // { title: "招聘会", link: "/jobfair.html", index: "2" },
        // { title: "通知公告", link: "/notice.html", index: "3" },
        // { title: "就业动态", link: "/notice.html?key=2", index: "4" },
        // { title: "就业报告", link: "/report.html", index: "5" },
        // { title: "省内高校", link: "/school.html", index: "6" },
      ],
      swipeList: [
        // {
        //   id: 0,
        //   coverImgUrl: "/imgs/banner/banner03.png",
        //   title: "",
        //   url: "https://www.smartedu.cn/special/cpc20/",
        //   isExternal: true
        // },
        // {
        //   id: 2,
        //   coverImgUrl: "/imgs/banner/banner01.jpg",
        //   title: "",
        //   url: "https://hunbys.ncss.cn/student/jobfair/fairdetails.html?fairId=Y1s5NT6ZWo2kndMEhVthA7",
        //   isExternal: true
        // },
      ],
      swipeHeight: "31.25rem",
      option: {
        arrow: "never",
        indicatorPosition: "none",
      },
    };
  },
  props: {
    params: {
      type: Object,
      default() {
        return {
          menuIndex: "1",
          swipeHeight: "31.25rem",
        };
      },
    },
  },
  watch: {
    params: {
      handler(nVal) {
        if (nVal && nVal.menuIndex !== this.menuIndex) {
          this.init();
        }
      },
      deep: true,
    },
  },
  async created() {
    this.init();
  },
  async mounted() {
    // this.loading = true;
    var key = 'menus';
    var tmpMenus = tool.data.get(key);
    if (!tmpMenus) {
      var res = await this.$api.getNavList({});
      tmpMenus = res ?? [];
      tool.data.set(key, tmpMenus, 10 * 60)
    }
    tmpMenus.forEach(item => {
      this.menuList.push({ title: item.name, link: item.url, index: item.url })
    })
    var res1 = await this.$api.getNoticeList({ page: 1, pageSize: 5, category: '16713110746309' });
    this.swipeList = res1.items;
  },
  methods: {
    goSearch() {
      this.$emit("search", this.keyword);
    },
    goto(item) {
      window.location.href = item.link;
      aplus_queue.push({
        'action': 'aplus.record',
        'arguments': ['category_click', 'CLK', {
          //以下为事件属性
          category_name: item.title, category_id: item.link
        }]
      });
    },
    init() {
      this.menuIndex = this.params.menuIndex;
      this.swipeHeight = this.params.swipeHeight;
      this.swipeList = this.params.swipeList
        ? this.params.swipeList
        : this.swipeList;
      this.option = Object.assign({}, this.option, this.params.option || {});
    },

  },
};
</script>

<style scoped lang="scss">
// 轮播图宽度修改
// .banner{
//     min-height: 100%;
//     background-color: aqua;
// }
.swipe-wrap {
  //padding-left:230px;
}

.xian {
  width: 0.0625rem;
  height: 2.8125rem;
  background: #fff;
  margin: 0 1.5625rem;
  margin-top: 0.5rem;
}
</style>