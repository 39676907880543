<template>
  <div id="app">
    <m-header :params="headerParams"></m-header>

    <section class="block-wrap content-wrap">
      <!--教育服务-->
      <div class="col-left">
        <header-list :header-list="pageData.headerList" @change="changeHeaderList" @more="goMore"></header-list>
        <div class="panel-wrap">
          <div class="panel-menu">
            <div class="panel-menu-item" v-for="item in resultPanelMenu" :key="item.key"
              :class="{ active: item.key === pageState.panelMenuKey }" @click="changeMenuItem(item)">
              {{ item.title }}
            </div>
          </div>
          <div class="panel-body">
            <div class="panel-body-item" v-for="(item, i) in resultPanelBody" :key="i" @click="goSite(item)">
              <img class="item-pic" :src="item.icon" alt="" v-if="item.enable" />
              <p class="item-txt" v-if="item.enable">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--招聘日历-->
      <div class="date-wrap">
        <div class="date-title">招聘日历</div>
        <div class="date-body">
          <div class="cuz-calendar">
            <div class="el-card__header calendar-header">
              <img src="/imgs/calendar/date-l.png" class="calendar-header-btn" />
              <span class="card-tit">
                {{
                  pageData.currDate.getFullYear() +
                    "年" +
                    (pageData.currDate.getMonth() + 1)
                }}月
              </span>
              <img src="/imgs/calendar/date-r.png" class="calendar-header-btn" />
            </div>

            <el-calendar v-model="pageData.currDate" style="position: relative">
              <template slot="dateCell" slot-scope="scope">
                <template v-if="pageData.calendar.map(m=>m.day).indexOf(scope.data.day.trim()) >= 0">
                  <el-popover ref="popover" placement="top" width="325" trigger="hover" popper-class="date-popover"
                    :open-delay="50" @show="loadPopoverList(scope.data.day)">
                    <div class="popover-block" style="padding-bottom: 15px; height: 380px">
                      <div class="popover-item" v-for="item in datePopoverList[scope.data.day]" :key="item.id" @click="
                        goDetail(
                          item.type === 0 ? 'jobfair' : 'career',
                          item
                        )
                      ">
                        <div class="po-tag">
                          <template v-if="item.type === 0">
                            <div class="main-circle">双</div>
                          </template>
                          <template v-if="item.type === 1">
                            <div class="main-circle">宣</div>
                          </template>
                        </div>
                        <div class="po-content">
                          <p class="po-content-tit">{{ item.title }}</p>
                          <p v-if="item.type === 1" class="tip-font">
                            {{ item.meetTime }}
                            <span style="margin-left: 20px">{{
                              item.place
                            }}</span>
                          </p>
                          <p v-if="item.type === 0" class="tip-font">
                            {{ item.meetTime }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div slot="reference">
                      <div style="position: relative">
                        <p class="has-data" style="height: 17px;width:17px;right:-8px"><span style="position: relative;top: -8px;font-size: 12px;">{{pageData.calendar.filter(m=>m.day==scope.data.day)[0].count}}</span></p>
                        {{ scope.data.day.split("-")[2] }}
                      </div>
                    </div>
                  </el-popover>
                </template>
                <p v-else>
                  {{ scope.data.day.split("-")[2] }}
                </p>
              </template>
            </el-calendar>
          </div>
        </div>
      </div>
    </section>
    <!--招聘信息-->
    <section class="block-wrap content-wrap">
      <div class="block-body">
        <header-list :header-list="pageData.infoTabList" @change="changeBlockList" @more="goMore"></header-list>

        <div class="block-content mt10">
          <template v-if="
            pageData.infoList[currInfoTab.key] &&
            pageData.infoList[currInfoTab.key].length > 0
          ">
            <template v-for="item in pageData.infoList[currInfoTab.key]">
              <block-job-fair v-if="currInfoTab.key === 'jobfair'" :item="item"
                @click="goDetail('jobfair', item)" :key="item.id"></block-job-fair>
              <block-career v-if="currInfoTab.key === 'career'" :item="item"
                @click="goDetail('career', item)" :key="item.id"></block-career>
              <block-job v-if="currInfoTab.key === 'jobs'" :item="item" @click="goDetail('jobs', item)"
                :key="item.id"></block-job>
            </template>
          </template>
          <template v-else>
            <div class="empty-wrap">
              <el-empty description="暂无数据"></el-empty>
            </div>
          </template>
        </div>
      </div>
    </section>
    <!--就业资讯-->
    <section class="block-wrap content-wrap">
      <div class="block-body">
        <header-list :header-list="pageData.newTabList" @change="changeNewList" @more="goMore"></header-list>
        <div class="block-content mt10">
          <template v-if="
            pageData.newList[currNewsTab.key] &&
            pageData.newList[currNewsTab.key].length > 0
          ">
            <template v-for="item in pageData.newList[currNewsTab.key]">
              <block-notice :item="item" :key="item.id"
                @click="goDetail(currNewsTab.type, item, currNewsTab.key)"></block-notice>
            </template>
          </template>
          <template v-else>
            <div class="empty-wrap">
              <el-empty description="暂无数据"></el-empty>
            </div>
          </template>
        </div>
      </div>
    </section>
    <!--常用链接-->
    <section class="block-wrap content-wrap" v-if="pageData.linkList && pageData.linkList.length > 0">
      <div class="block-body">
        <header-list :header-list="pageData.linkTabList"></header-list>
        <div class="block-content mt20">
          <vue-seamless-scroll :data="pageData.linkList" :class-option="classOption" class="links-warp">
            <ul class="item">
              <li v-for="(item, i) in pageData.linkList" :key="i">
                <a :href="item.url" :title="item.title" target="_blank"><img :src="item.imgUrl" class="link-img"
                    :alt="item.title" /></a>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </section>

    <m-footer></m-footer>

    <float-window :item="pageData.floatWindow" :show="true"></float-window>

    <el-dialog :visible.sync="dialogVisible" width="500px">
      <div class="layui-layer layui-layer-page" id="layui-layer3">
        <div class="layui-layer-title" style="cursor: move">请选择办理地点</div>
        <div id="" class="layui-layer-content" style="height: 193px">
          <div id="box1" class="layui-layer-wrap" style="display: block">
            <div style="padding: 0 12px 0px 20px">
              各市州中考成绩查询公布时间请以各市州教育局公布时间为准
            </div>

            <ul class="city-item">
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/SNm3y9fASN6grrmdLcfKOw/index.html"
                  class="layui-btn-primary" target="_blank">长沙市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/2xaaZno4TwyicfWgHxPCSA/index.html"
                  class="layui-btn-primary" target="_blank">衡阳市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200002"
                  class="layui-btn-primary" target="_blank">株洲市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200004"
                  class="layui-btn-primary" target="_blank">湘潭市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200003"
                  class="layui-btn-primary" target="_blank">邵阳市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200012"
                  class="layui-btn-primary" target="_blank">岳阳市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/5oFVdjGxTg-YcvKFDN7Pfg/index.html"
                  class="layui-btn-primary" target="_blank">常德市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/1BGpwqudQje72NOPrJXZeA/index.html"
                  class="layui-btn-primary" target="_blank">张家界市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200006"
                  class="layui-btn-primary" target="_blank">益阳市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200008"
                  class="layui-btn-primary" target="_blank">郴州市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200013"
                  class="layui-btn-primary" target="_blank">永州市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200010"
                  class="layui-btn-primary" target="_blank">怀化市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/uSAU80AORre3wvqE5iq1Ow/index.html?cityCode=200009"
                  class="layui-btn-primary" target="_blank">娄底市</a>
              </li>
              <li>
                <a href="http://zwfw-new.hunan.gov.cn/hnzwfw/portal/TNxAJJzoTACiplleUFtUFA/index.html"
                  class="layui-btn-primary" target="_blank">湘西州</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import HeaderList from "@/components/headerList";
import vueSeamlessScroll from "vue-seamless-scroll";
import mFooter from "@/components/mFooter";
import mHeader from "@/components/mHeader";
import blockJobFair from "@/components/blockJobFair";
import blockNotice from "@/components/blockNotice";
import BlockCareer from "@/components/blockCareer";
import BlockJob from "@/components/blockJob";
import FloatWindow from "@/components/floatWindow";

export default {
  name: "mHome",
  data() {
    return {
      dialogVisible: false,
      headerParams: {
        menuIndex: "1",
        swipeHeight: "500px",
        swipeList: [
          // {
          //   id: 0,
          //   coverImgUrl: "/imgs/banner/banner03.png",
          //   title: "",
          //   url: "https://www.smartedu.cn/special/cpc20/",
          //   isExternal: true
          // },
          // {
          //   id: 2,
          //   coverImgUrl: "/imgs/banner/banner01.jpg",
          //   title: "",
          //   url: "https://hunbys.ncss.cn/student/jobfair/fairdetails.html?fairId=Y1s5NT6ZWo2kndMEhVthA7",
          //   isExternal: true
          // },
        ],
        option: {
          arrow: "hover",
          indicatorPosition: "",
        },
      },
      loadingCalendar: true,
      calendarListPage: {
        pagesize: 5,
        page: 1,
        total: 1,
      },
      popoverLoading: false,
      datePopoverList: {},
      popoverDate: "",
      pageData: {
        headerList: {
          title: "教育服务",
          tagList: [
            {
              title: "按学段",
              key: "level",
              url: "http://zwfw-new.hunan.gov.cn/hnzwfw/1/186/196/43291/43292/index.htm",
            },
            {
              title: "按用户",
              key: "user",
              url: "http://zwfw-new.hunan.gov.cn/hnzwfw/1/186/196/43291/43299/index.htm",
            },
            {
              title: "按专题",
              key: "theme",
              url: "http://zwfw-new.hunan.gov.cn/hnzwfw/1/186/196/43291/43306/index.htm",
            },
            {
              title: "电子证照",
              key: "card",
              link: "http://zwfw-new.hunan.gov.cn/hnvirtualhall/serviceguide/jsp/dzzzlist.jsp",
            },
          ],
          tagIndex: 0,
          more: true,
        },
        infoTabList: {
          title: "招聘信息",
          tagList: [
            { title: "双选会", key: "jobfair", type: "jobfair" },
            { title: "宣讲会", key: "career", type: "jobfair" },
            { title: "岗位信息", key: "jobs", type: "jobs" },
          ],
          tagIndex: 0,
          more: true,
        },
        newTabList: {
          title: "就业资讯",
          tagList: [
            { title: "通知公告", key: "373064683921733", type: "notice", menuIndex: "/notice.html" },
            { title: "就业动态", key: "373064839737669", type: "notice", menuIndex: "/notice.html?key=373064839737669" },
            // { title: "事业单位", key: "375581073695045", type: "notice", menuIndex: "/notice.html?key=375581073695045" },
          ],
          tagIndex: 0,
          more: true,
        }, //就业资讯
        linkTabList: {
          title: "友情链接",
          tagList: [],
          tagIndex: 0,
          more: false,
        },
        panelMenuList: [
          {
            title: "全部",
            key: "",
            type: "level",
            // index: [3, 4, 0, 8, 9, 10, 11, 12, 13, 14, 5, 1],
            index: [3, 4, 0, 9, 10, 11, 12, 5, 1, 2, 7, 17],
          },
          { title: "学前教育", key: "1", type: "level", index: [] },
          { title: "义务教育", key: "2", type: "level", index: [0, 1, 2] },
          {
            title: "高中阶段",
            key: "3",
            type: "level",
            index: [3, 4, 5, 6, 7],
          },
          {
            title: "高等教育",
            key: "4",
            type: "level",
            // index: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            index: [9, 10, 11, 12, 15, 16, 17, 18, 2, 7, 17],
          },
          {
            title: "成人",
            key: "5",
            type: "level",
            index: [19, 20, 21, 22, 23],
          },

          {
            title: "全部",
            key: "",
            type: "user",
            // index: [4, 3, 0, 9, 10, 8, 11, 12, 13, 14, 5, 1],
            index: [4, 3, 0, 9, 10, 11, 12, 5, 1, 2, 7, 17],
          },
          {
            title: "学生/家长",
            key: "6",
            type: "user",
            // index: [0, 1, 3, 4, 5, 6, 8, 11, 12, 13, 14, 16],
            index: [0, 1, 3, 4, 5, 6, 11, 12, 16, 2, 7, 17],
          },
          { title: "学校", key: "7", type: "user", index: [23] },
          { title: "教育部门", key: "8", type: "user", index: [2] },
          { title: "社会机构", key: "9", type: "user", index: [2, 9, 10] },

          {
            title: "全部",
            key: "",
            type: "theme",
            // index: [3, 4, 0, 8, 9, 10, 11, 12, 13, 14, 5, 1],
            index: [3, 4, 0, 9, 10, 11, 12, 5, 1, 2, 7, 17],
          },
          {
            title: "考试",
            key: "10",
            type: "theme",
            index: [0, 3, 6, 15, 23, 24, 25, 26, 27, 28],
          },
          {
            title: "证书",
            key: "11",
            type: "theme",
            // index: [5, 7, 8, 14, 16, 18, 22, 29, 30],
            index: [5, 7, 18, 22, 29, 30],
          },
          { title: "学历", key: "12", type: "theme", index: [] },
          {
            title: "毕业",
            key: "13",
            type: "theme",
            index: [9, 10, 11, 12, 13, 17],
          },
          { title: "其它", key: "14", type: "theme", index: [1, 2] },
        ],
        panelBodyList: [
          {
            title: "湖南省中考成绩查询",
            icon: "/imgs/panel/20220630180747601.png",
            dialog: 1,
            enable:true
          },
          {
            title: "中小学生学籍查询",
            icon: "/imgs/panel/20190903174619363.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/4NGsSudQREO7sOqOrS-7dg/index.html#resultDiv",
            enable:true
          },
          {
            title: "湖南省学校机构代码查询",
            icon: "/imgs/panel/20190826142340186.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/P6XvFk6pTBKHSI9C6I_f9g/index.html",
            enable:true
          },
          {
            title: "高考成绩查询",
            icon: "/imgs/panel/20190611100331669.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/1ZB6IhzFTj2kr6z5Iy9Jhg/index.html",
            enable:true
          },
          {
            title: "高考录取状态查询",
            icon: "/imgs/panel/20190709121220743.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/jUaOrGisQrCPB2E_f28eJA/index.html",
            enable:true
          },
          {
            title: "湖南省普通高中毕业生信息查询",
            icon: "/imgs/panel/20191021103016519.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/60zWlmlzSoS10jQhEiCNyQ/index.html",
            enable:true
          },
          {
            title: "高中学业水平考试成绩查询",
            icon: "/imgs/panel/20190729092209499.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/ktihF9JPQmifIAllZyoVZQ/index.html",
            enable:true
          },
          {
            title: "湖南省中等职业学校学历证书查询",
            icon: "/imgs/panel/20190611103750445.png",
            link: "https://zzxlrz.hnedu.cn/xlrz/toAcademicAttestation",
            enable:true
          },
          {
            title: "省内普通高等学校毕业生初次办理《报到证》服务",
            icon: "/imgs/panel/20191217102636422.png",
            link: "http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=C90B02D84BD00001E3111AD0F71017D4&type=xndtbm&dghy=&cscjwt=",
            enable:true
          },
          {
            title: "湖南省普通高校毕业生生源信息速览",
            icon: "/imgs/panel/20220114093202362.png",
            link: "http://wshy.hunbys.net/studentInfo/index",
            enable:false
          },
          {
            title: "湖南省普通高校毕业生生源信息电子书",
            icon: "/imgs/panel/20220114093215576.png",
            link: "http://hy.hunbys.net/2022dzs/index.html",
            enable:false
          },
          {
            title: "普通高等学校毕业生托管档案证明服务",
            icon: "/imgs/panel/20191217102342100.png",
            link: "http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=C90B02EDAFD00001BC29D0F91D203F90&type=xndtbm&dghy=&cscjwt=",
            enable:true
          },
          {
            title: "普通高等学校毕业生托管档案转出服务",
            icon: "/imgs/panel/20191217102305391.png",
            link: "http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=C90B02EBE1000001E7DDEC30884012C0&amp;type=xndtbm&amp;dghy=&amp;cscjwt=",
            enable:true
          },
          {
            title: "省内普通高等学校毕业生改办《报到证》服务",
            icon: "/imgs/panel/20191217102231628.png",
            link: "http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=C90B02DFF64000019DBC17D02466C4F0&amp;type=xndtbm&amp;dghy=&amp;cscjwt=",
            enable:true
          },
          {
            title: "省内普通高等学校毕业生补办《报到证》服务",
            icon: "/imgs/panel/20191217102636422.png",
            link: "http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=C90B02DD25400001717EFD259806B650&amp;type=xndtbm&amp;dghy=&amp;cscjwt=",
            enable:true
          },
          {
            title: "高考录取状态查询",
            icon: "/imgs/panel/20190709121220743.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/jUaOrGisQrCPB2E_f28eJA/index.html",
            enable:true
          },
          {
            title: "就业报到证查询",
            icon: "/imgs/panel/20190611103637166.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/q7eqKY6aT_yXeIP-9Rx8XA/index.html",
            enable:true
          },
          {
            title: "毕业生档案查询",
            icon: "/imgs/panel/20190611103551972.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/l20zz6XDTF-kF1Tt7Gs3Dg/index.html",
            enable:true
          },
          {
            title: "学位证书网上查询",
            icon: "/imgs/panel/20190730100227623.png",
            link: "http://app.gjzwfw.gov.cn/jmopen/webapp/html5/jybxwzswscx/index.html",
            enable:true
          },
          {
            title: "湖南省2021年成人高校招生考试成绩查询",
            icon: "/imgs/panel/20211203164403739.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/RJByfhn1TOeircWOvfE_5A/index.html",
            enable:true
          },
          {
            title: "研究生招生考试成绩查询",
            icon: "/imgs/panel/20190611103651684.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/woLKFhmYRpyrBmKxuKrCKw/index.html",
            enable:true
          },
          {
            title: "湖南省自学考试成绩查询",
            icon: "/imgs/panel/20190611103811963.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/i73tbFo7SOGtE516ECJv0A/index.html",
            enable:true
          },
          {
            title: "普通话证书查询",
            icon: "/imgs/panel/20190730100216961.png",
            link: "http://app.gjzwfw.gov.cn/jmopen/webapp/html5/pthzscx/index.html",
            enable:true
          },
          {
            title: "中小学教师资格考试（NETC）成绩查询",
            icon: "/imgs/panel/20190730100155750.png",
            link: "http://app.gjzwfw.gov.cn/jmopen/webapp/html5/jybzxxjszgkscjcx/index.html",
            enable:true
          },
          {
            title: "研究生招生考试成绩查询",
            icon: "/imgs/panel/20190611103651684.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/woLKFhmYRpyrBmKxuKrCKw/index.html",
            enable:true
          },
          {
            title: "湖南省自学考试成绩查询",
            icon: "/imgs/panel/20190611103811963.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/i73tbFo7SOGtE516ECJv0A/index.html",
            enable:true
          },
          {
            title: "普通话水平测试成绩查询",
            icon: "/imgs/panel/20190611103620415.png",
            link: "http://zwfw-new.hunan.gov.cn/hnzwfw/portal/SjEJ6vz_R7Ct0oD1lEuIQg/index.html",
            enable:true
          },
          {
            title: "中国少数民族汉语水平等级考试（MHK）成绩查询",
            icon: "/imgs/panel/20190730100205871.png",
            link: "http://app.gjzwfw.gov.cn/jmopen/webapp/html5/jybzgssmzhyspdjkscjcx/index.html",
            enable:true
          },
          {
            title: "全国大学英语四、六级考试（CET）成绩查询",
            icon: "/imgs/panel/20190730095104818.png",
            link: "http://cet.neea.edu.cn/cet",
            enable:true
          },
          {
            title: "国境外学历学位认证结果查询",
            icon: "/imgs/panel/20190730095044304.png",
            link: "http://app.gjzwfw.gov.cn/jmopen/webapp/html5/gjwxlxwrzjgcxpc/index.html",
            enable:true
          },
          {
            title: "中国高等教育学历证书查询",
            icon: "/imgs/panel/20190730095028466.png",
            link: "http://app.gjzwfw.gov.cn/jmopen/webapp/html5/jybgdjyxlcx/index.html",
            enable:true
          },
        ],
        infoList: {
          jobfair: [],
          career: [],
          jobs: [],
        }, //招聘信息
        newList: {
          373064683921733: [],
          373064839737669: [],
          375581073695045: [],
        }, //就业资讯
        linkList: [], //常用链接

        currDate: new Date(),
        calendar: [],
        calendarAllList: [],
        floatWindow: {},
      },
      pageState: {
        panelMenuKey: "",
        panelMenu: {
          title: "全部",
          key: "",
          type: "level",
          // index: [3, 4, 0, 8, 9, 10, 11, 12, 13, 14, 5, 1],
          index: [3, 4, 0, 9, 10, 11, 12, 5, 1, 2, 7, 17],
        },
      },
    };
  },
  watch: {
    "pageData.currDate": {
      handler: function handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.getCalendar) {
            this.getCalendar();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    //教育服务面板列表
    resultPanelBody() {
      let list = this.pageData.panelBodyList;
      if (this.pageState.panelMenu.index) {
        list = this.pageState.panelMenu.index.map((i) => {
          return list[i];
        });
      }
      return list.filter(item=>item.enable);
    },
    //教育服务左侧菜单
    resultPanelMenu() {
      let list = this.pageData.panelMenuList;
      if (this.pageData.headerList.tagList[this.pageData.headerList.tagIndex]) {
        list = this.pageData.panelMenuList.filter((item) => {
          return (
            item.type ===
            this.pageData.headerList.tagList[
              this.pageData.headerList.tagIndex
            ].key || !item.type
          );
        });
      }
      return list;
    },
    //链接滚动样式
    classOption() {
      return {
        direction: 2,
        limitMoveNum: 6,
      };
    },
    //招聘信息当前Tab
    currInfoTab() {
      return this.pageData.infoTabList.tagList[
        this.pageData.infoTabList.tagIndex
      ];
    },
    currNewsTab() {
      return this.pageData.newTabList.tagList[
        this.pageData.newTabList.tagIndex
      ];
    },
  },
  components: {
    BlockJob,
    BlockCareer,
    HeaderList,
    vueSeamlessScroll,
    mFooter,
    mHeader,
    blockJobFair,
    blockNotice,
    FloatWindow,
  },
  methods: {
    goSite(item) {
      if (item.link) {
        window.open(item.link);
      }
      if (item.dialog > 0) {
        this.dialogVisible = true;
      }
    },
    changeHeaderList(i) {
      this.pageData.headerList.tagIndex = i;
      this.$nextTick(() => {
        this.pageState.panelMenuKey = "";
        this.pageState.panelMenu = this.resultPanelMenu[0];
      });
    },
    goMore(item) {
      if (item.type) {
        let url = `/${item.type}.html`;
        if (item.type !== item.key) {
          url += `?key=${item.key}`;
        }
        window.open(url);
      } else {
        window.open(
          "http://zwfw-new.hunan.gov.cn/hnzwfw/1/186/196/43291/43292/index.htm"
        );
      }
    },
    changeMenuItem(item) {
      this.pageState.panelMenu = item;
      this.pageState.panelMenuKey = item.key;
    },
    changeBlockList(i) {
      this.pageData.infoTabList.tagIndex = i;
    },
    changeNewList(i, obj) {
      this.getNoticeList(obj.key);
      this.pageData.newTabList.tagIndex = i;
    },
    goDetail(type, item, key) {
      if(item.isExternal){
        window.open(item.url)
      }else{
        this.$util.gotoDetail(type, item.id, key);
      }
    },
    loadPopoverList(day) {
      if (this.popoverDate === day) return;

      //加载日历招聘会
      if (!this.popoverLoading) {
        this.popoverLoading = true;
        this.popoverDate = day;

        let month =
          this.pageData.currDate.getFullYear() +
          "-" +
          (parseInt(this.pageData.currDate.getMonth()) + 1);

        this.$api
          .getMothEventDay({
            month: month,
            day: day,
          })
          .then((data) => {
            if (data) {
              this.$set(this.datePopoverList, day, data);
            } else {
              this.datePopoverList[day] = [];
            }
            this.popoverLoading = false;

            this.$nextTick(() => {
              let popover = this.$refs["popover"];
              if (popover) {
                if (popover.updatePopper) {
                  popover.updatePopper();
                } else {
                  // 因为表格用到了 fixed 列，所以 selectPopover 会是一个列表
                  popover.forEach((item) => {
                    // 列表中只有一个 popover 是有用的
                    if (item.updatePopper) {
                      item.updatePopper();
                    }
                  });
                }
              }
            });
          });
      }
    },

    getJobFairList() {
      this.$api.getJobFairList({ page: 1, pageSize: 8 }).then((data) => {
        this.pageData.infoList["jobfair"] = data.items;
      });
    },
    getCareerList() {
      this.$api.getCareerList({ page: 1, pageSize: 9 }).then((data) => {
        this.pageData.infoList["career"] = data.items;
      });
    },
    getNoticeList(type) {
      this.$api
        .getNoticeList({ category: type, page: 1, pageSize: 8 })
        .then((data) => {
          this.pageData.newList[type] = data.items;
        });
    },
    getJobsList() {
      this.$api.getJobsList({ page: 1, pageSize: 12 }).then((data) => {
        //1.获取轮播图-常用链接-飘窗数据
        this.pageData.infoList["jobs"] = data.items;
      });
    },
    getCalendar() {
      this.$api
        .getCalendar({
          year: this.pageData.currDate.getFullYear(),
          month: parseInt(this.pageData.currDate.getMonth()) + 1,
        })
        .then((data) => {
          //1.获取轮播图-常用链接-飘窗数据
          this.pageData.calendar = data;
        });
    },
    //初始化首页
    initData() {
      this.getJobFairList();
      this.getCareerList();
      this.getJobsList();
      this.getNoticeList(this.pageData.newTabList.tagList[0].key);
      //获取基本信息
      this.$api.getHomeData({}).then((data) => {
        //1.获取轮播图-常用链接-飘窗数据
        this.pageData.linkList = data.linkList;
        this.pageData.floatWindow = data.floatWindow;
      });
    },
  },
  async created() {
    this.getCalendar();
    this.initData();
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/sass/common.scss";

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  flex-shrink: 0;
}

.title-col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .title-txt {
    width: 130px;
    height: 31px;
    font-size: 30px;
    font-weight: 400;
    color: #252525;
    position: absolute;
    text-align: center;
    top: -2px;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: -12px;
      width: 130px;
      height: 15px;
      background: #e7effa;
    }
  }

  .tag-list {
    display: flex;
    margin-left: 146px;

    .tag-block {
      position: relative;
      width: 130px;
      height: 40px;
      background: #f3f3f3;
      border-radius: 5px;
      margin-left: 15px;
      font-size: 16px;
      font-weight: bold;
      color: #252525;
      line-height: 40px;
      text-align: center;

      &.active {
        background-color: $mainColor;
        color: #ffffff;

        &:after {
          content: "";
          position: absolute;
          left: 50%;
          margin-left: -5px;
          width: 0px;
          height: 0px;
          border-top: 8px solid $mainColor;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          bottom: -8px;
        }
      }
    }
  }

  .more {
    font-size: 14px;
    font-weight: 400;
    color: #1963d0;
    cursor: pointer;
  }
}

.content-wrap.block-wrap {
  margin: 40px auto;
  display: flex;
  justify-content: space-between;

  .block-body {
    width: 100%;
  }

  .block-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.col-left {
  width: 950px;

  .panel-wrap {
    margin-top: 25px;
    height: 420px;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .panel-menu {
      width: 140px;
      height: 100%;
      background: #f3f3f3;

      .panel-menu-item {
        height: 69px;
        line-height: 69px;
        font-size: 15px;
        color: #333333;
        text-align: center;
        border-bottom: 1px solid #fff;
        cursor: pointer;

        &.active {
          background: #e7effa;
          border-bottom: 2px solid $mainColor;
          font-weight: bold;
          color: #1565d2;
        }

        &:hover {
          color: $mainColor;
        }
      }
    }

    .panel-body {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .panel-body-item {
        width: 25%;
        text-align: center;
        height: 140px;
        overflow: hidden;
        padding: 0px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .item-pic {
          width: 48px;
          height: 48px;
        }

        .item-txt {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;

          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.popover-item {
  display: flex;
  padding: 12px 5px;
  border-bottom: 1px dashed #e9e8e8;
  cursor: pointer;

  &:hover {
    .po-content-tit {
      color: $mainColor !important;
    }
  }

  .main-circle {
    @extend .circle;
    background-color: #3889ff;
  }

  .success-circle {
    @extend .circle;
    background-color: $successColor;
  }

  .po-content {
    margin-left: 10px;

    .po-content-tit {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
    }
  }
}

.state {
  width: 50px;
  height: 20px;
  border-radius: 2px;
  font-size: 12px;
  padding: 2px 8px;
  line-height: 20px;
  margin-left: 8px;
  box-sizing: border-box;
  position: relative;
  top: -1px;

  &.state-success {
    background: #2eb96f;
    color: #ffffff;
  }

  &.state-warn {
    background: #ff8355;
    color: #ffffff;
  }
}

.links-warp {
  overflow: hidden;
  height: 90px;
  width: 1400px;

  ul.item {
    width: 1400px;

    li {
      float: left;
      margin-right: 12px;
    }

    .link-img {
      width: 268px;
      height: 90px;
    }
  }
}

.empty-wrap {
  width: 100%;
  text-align: center;
}

.popover-block {
  max-height: 320px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #99a9bf;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #d3dce6;
  }
}

.city-item {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  li {
    display: inline-block;
    margin: 5px 8px;
  }

  .layui-btn-primary {
    border: 1px solid #666;
    color: #666;
    padding: 2px 10px;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 5px;
  }
}
</style>

<style lang="scss">
@import "@/common/sass/common.scss";

.date-wrap {
  width: 415px;

  .date-title {
    height: 60px;
    background: #ffc525;
    border-radius: 6px 6px 0px 0px;
    font-size: 20px;
    font-weight: bold;
    color: #4d3903;
    text-align: center;
    line-height: 60px;
  }

  .date-body {
    margin-top: 5px;
    height: 427px;
    background: #f3f3f3;
    border: 1px solid #f2f2f2;
    padding: 10px 20px 28px;
    box-sizing: border-box;
  }
}

.cuz-calendar {
  .calendar-header {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .calendar-header-btn {
      height: 20px;
    }

    .card-tit {
      font-size: 22px;
      font-weight: bold;
      color: #252525;
    }
  }

  .el-calendar__body {
    padding: 0;
    background-color: #f3f3f3;

    .el-calendar-table {
      border-radius: 10px 10px 0px 0px;
      border-bottom: 1px solid #f3f3f3;
      overflow: hidden;

      thead th {
        padding: 10px 0;
        border-bottom: 1px solid #f3f3f3;
        background-color: $mainColor;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
      }

      tr td {
        border: none !important;
        text-align: center;
        padding: 5px;
        font-size: 14px;

        &.is-selected {
          background-color: transparent;

          .el-calendar-day {
            background-color: $mainColor;
            border-radius: 50%;
            color: #ffffff;
          }
        }
      }

      .el-calendar-day {
        height: 32px;
        line-height: 32px;
        width: 32px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0;

        &:hover {
          background-color: $mainColor;
          color: #ffffff;
        }
      }

      .has-data {
        background: #ff9000;
        border-radius: 50%;
        color: #fff;
        width: 6px;
        height: 6px;
        position: absolute;
        right: -5px;
        top: 0;
      }
    }

    .el-calendar-table__row:nth-of-type(2),
    .el-calendar-table__row:nth-of-type(4),
    .el-calendar-table__row:nth-of-type(6) {
      background-color: #ffffff;
    }
  }

  .el-calendar__header {
    display: block;
    position: absolute;
    width: 100%;
    left: -20px;
    top: -61px;
    overflow: hidden;
    opacity: 0;

    .el-calendar__title {
      display: none;
    }

    .el-button-group {
      &>.el-button:first-child {
        width: 10px;
        overflow: hidden;
        margin-left: 40px;
      }

      &>.el-button:last-child {
        width: 10px;
        overflow: hidden;
        margin-left: 225px;
      }

      &>.el-button:not(:first-child):not(:last-child) {
        display: none;
      }
    }
  }
}
</style>