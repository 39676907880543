<template>
    <div>
        <div v-if="show"
             @mouseover="mouseover"
             @mouseout="mouseout"
             class="box"
             :style="{'top':top + 'px','left':left + 'px'}">
            <span @click="close">X</span>
            <div :style="{width:`${width}px`,height:`${height}px`}">
                <a :href="item.link" target="_blank">
                    <img class="" :src="item.image" style="border:0" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "floatWindow",
        data() {
            return {
                show: true, // 是否展现飘窗
                stepX: 1, // 水平方向的步长
                stepY: 1, // 垂直方向的步长
                timer: null, // 定时器
                maxTop: 0, // 最大的 top 值
                maxLeft: 0, // 最大的 left 值
                top: 0,
                left: 0,
            }
        },
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            width: {
                type: Number,
                default: 290
            },
            height: {
                type: Number,
                default: 200
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            beforeDestroy() {
                // dom 销毁前清除定时器
                clearInterval(this.timer)
            },
            // 初始化飘窗规则
            init() {
                // 设置最大的top和left值：根元素可视区域宽高 - 飘窗的宽高 - 边距
                this.maxTop = document.documentElement.clientHeight - 150 - 20
                this.maxLeft = document.documentElement.clientWidth - 200 - 20

                // 设置 top 和 left 的初始值
                this.top = 0
                this.left = 0

                // 创建定时器前清除定时器，避免类似在 onresize 中调用 init() 时，产生多个定时器
                clearInterval(this.timer)
                /*  校验缓存关闭后，60分钟内不再显示
                 let flowWindowState = localStorage.getItem('flowWindowState');

                           if (flowWindowState) {
                               let obj = JSON.parse(flowWindowState);
                               let curTime = (new Date()).getTime();
                               if (curTime - obj.time < 60000 * 60 && !obj.state) {
                                   this.show = false;
                               }
                           }
                */

                if (this.show) {
                    this.timer = setInterval(() => {
                        this.move()
                    }, 20)
                    this.onresize()
                }

                if (!this.item?.image) {
                    this.show = false;
                }
            },
            // 移动函数
            move() {
                if (this.top >= this.maxTop || this.top < 0) {
                    this.stepY = -this.stepY
                }
                if (this.left >= this.maxLeft || this.left < 0) {
                    this.stepX = -this.stepX
                }

                this.top += this.stepY
                this.left += this.stepX
            },
            // 鼠标悬浮在飘窗时停止移动
            mouseover() {
                clearInterval(this.timer)
            },
            // 鼠标离开飘窗时恢复移动
            mouseout() {
                clearInterval(this.timer)
                this.timer = setInterval(() => {
                    this.move()
                }, 20)
            },
            // 关闭飘窗
            close() {
                clearInterval(this.timer)
                this.show = false
                localStorage.setItem('flowWindowState', JSON.stringify({
                    state: this.show,
                    time: (new Date()).getTime()
                }))
            },
            // 窗口大小调整时重置飘窗规则
            onresize() {
                const that = this
                window.onresize = function () {
                    that.init()
                }
            },
        },
        watch: {
            item(val) {
                if(val)
                    this.show=true;
                this.init()
            }
        },
    }
</script>

<style scoped lang="scss">
    .box {
        background: #fff;
        border-radius: 5px;
        position: fixed;
        padding: 5px;
        top: 0;
        left: 0;
        z-index: 9999;
        box-shadow: 3px 2px 5px rgba(66, 66, 66, .5);

        > span {
            width: 24px;
            height: 24px;
            background-color: rgba(255, 255, 255, .7);
            position: absolute;
            right: 15px;
            top: 12px;
            color: #666666;
            cursor: pointer;
            text-align: center;
            line-height: 24px;
            border-radius: 24px;
            display: block;
            font-weight: bold;
            opacity: .5;
        }

    }
</style>