<template>
    <div class="job-item" :style="mStyle" @click="clickHandle">
        <div class="job-info">
            <div class="job-info-text">
                <span class="info-tit" :title="item.jobName">{{item.jobName}}</span>
                <span class="info-salary">{{item.minSalary}}K-{{item.maxSalary}}K/月</span>
            </div>
            <div class="under-row">
                <span class="job-tip">{{item.workAddress}}</span>
                <span class="job-tip">{{degreeFormat(item.lowMajorXl)}}</span></div>
        </div>
        <div class="job-company">
            <span class="company-tit">{{item.companyName}}</span><br/>
            <!-- <span class="company-tit">来源：{{item.schoolName}}</span> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "blockJob",
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            mStyle: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data(){
            return {
                degreeList: [{name:'专科及以上',value:1}, {name:'本科及以上',value:2}, {name:'硕士及以上',value:3}, {name:'博士及以上',value:4}],
            }
        },
        methods: {
            degreeFormat(value){
				const values = this.degreeList.filter(item => item.value == value)
				if (values.length > 0) {
					return values[0].name
				}
                return "不限"
            },
            clickHandle() {
                this.$emit('click')
            }
        },
    }
</script>

<style scoped lang="scss">
    .job-item {
        width: 330px;
        margin: 10px 25px 10px 0;
        border: 1px solid #F2F2F2;
        padding: 18px;
        box-sizing: border-box;
        cursor: pointer;

        &:nth-of-type(4),
        &:nth-of-type(8),
        &:nth-of-type(12),
        &:nth-of-type(16)
        {
            margin-right: 0;
        }

        .job-tip {
            color: #999999;
            line-height: 24px;
            padding: 0 15px;
            position: relative;
            font-size: 14px;

            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 14px;
                background-color: #DADADA;
                right: 0;
                top: 2px
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;

                &:after {
                    content: none;
                }
            }
        }

        .under-row {
            margin-top: 10px;
        }

        .job-company {
            padding-top: 14px;

            .company-tit {
                font-size: 14px;
                color: #666666;
                line-height: 24px;
            }
        }

        .job-info {
            padding-bottom: 14px;

            .job-info-text {
                display: flex;
                justify-content: space-between;

                .info-tit {
                    font-size: 16px;
                    font-weight: bold;
                    color: #1963D0;
                    line-height: 26px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .info-salary {
                    margin-left: 15px;
                    font-size: 14px;
                    color: #FF9000;
                    line-height: 26px;
                    width: 85px;
                    flex-shrink: 0;
                    justify-content: flex-end;
                }
            }

            border-bottom: 1px dashed #E9E8E8;
        }
    }
</style>