import axios from 'axios'
import qs from 'qs'

// const ajaxUrl = 'http://localhost:8088/'
// const ajaxUrl = 'https://ts_hn_api.bysjy.com.cn/'//'http://127.0.0.1:4523/m1/1845223-0-default/';
const ajaxUrl = 'https://serveapi.hunbys.net/'
const picUrl = 'https://o.bysjy.com.cn/'
const picPath = 'http://pic.bibibi.net/'
const aliPath = 'http://yun-campus-res.oss-cn-shenzhen.aliyuncs.com/'
const aliPathHttps = 'https://yun-campus-res.oss-cn-shenzhen.aliyuncs.com/'


let getValue = function (obj, cols, params) {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
        var url = '';
        (function getKeysFn(o, _index) {
            if (cols[_index]) {
                var key = cols[_index]
                if (Object.prototype.toString.call(o[key]) === '[object Object]') {
                    getKeysFn(o[key], _index + 1)
                } else {
                    url = o[key]
                    if (!url || url.indexOf('http') < 0) {
                        url = params.default_img || this.error_seeker_logo
                    } else if (url.indexOf('https') < 0) {
                        url = url.replace(aliPath, picUrl).replace(picPath, picUrl)
                    } else {
                        url = url.replace(aliPathHttps, picUrl)
                    }

                    if (url.indexOf('x-oss-process') < 0) {
                        if (params.style) {
                            url += ('?x-oss-process=style/' + params.style)
                        } else if (params.resize) {
                            url += ('?x-oss-process=image/resize,m_fill,' + params.resize)
                        }
                    }
                    o[key] = url
                }
            }
        })(obj, 0)
    } else if (Object.prototype.toString.call(obj) === '[object String]') {
        let o = obj
        if (!o || o.indexOf('http') < 0) {
            o = params.default_img || this.error_seeker_logo
        } else if (o.indexOf('https') < 0) {
            o = o.replace(aliPath, picUrl).replace(picPath, picUrl)
        } else {
            o = o.replace(aliPathHttps, picUrl)
        }
        if (o.indexOf('x-oss-process') < 0) {
            if (params.style) {
                o += ('?x-oss-process=style/' + params.style)
            } else if (params.resize) {
                o += ('?x-oss-process=image/resize,m_fill,' + params.resize)
            }
        } else {
            if (params.style) {
                o = replaceParamVal('x-oss-process', 'style/' + params.style, o)
            }
        }
        return o
    }
}
var replaceParamVal = function (paramName, replaceWith, str) {
    var oUrl = str || location.href.toString()
    var re = eval('/(' + paramName + '=)([^&]*)/gi')
    var nUrl = oUrl.replace(re, paramName + '=' + replaceWith)
    return nUrl
}
let ajax = axios.create({
    baseURL: ajaxUrl,
    timeout: 60000,
    withCredentials: false // 表示跨域请求时是否需要使用凭证”。这个“凭证”是在withCredentials开启时自动生成并保存在cookie中然后在http请求的时候带上
});
// HTTP request 拦截器
ajax.interceptors.request.use(
    (config) => {
        //token
        let token = "zGERzBtv8YVDcCrPlw0sRsBKdBhwo4aJBKlgSKJ5ZLg3OnPwntQ693PCS6iPNp2QM2zDlowzxF1UtePHspw2NQ_e_q__e_q_";
        let tokenKey = "WebSite-Authorization";
        config.headers[tokenKey] = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)
//常用函数
const utils = {
    error_seeker_logo: 'https://o.bysjy.com.cn/kzp/public/photo_default.png',

    get(url, params) {
        return new Promise((resolve) => {
            ajax({
                method: 'get',
                url: url,
                params: params
            }).then(response => {
                const {result, code, msg} = response.data;
                if (code !== 200) {
                    return resolve({
                        code: -1, msg: msg
                    })
                }
                resolve(result);
            }, error => {
                const {message} = error || {};
                return resolve({code: -1, url: url, msg: message || '请求失败'});
            })
        })
    },
    post(url, params) {
        return new Promise((resolve) => {
            ajax.post(url, qs.stringify(params)).then(response => {
                const {result, code, msg} = response.data;
                if (code !== 200) {
                    return resolve({
                        code: -1, msg: msg
                    })
                }
                resolve(result);
            }, error => {
                const {message} = error || {};
                return resolve({code: -1, url: url, msg: message || '请求失败'});
            })
        })
    },
    //获取参数
    getQueryString(name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        let r = window.location.search.substr(1).match(reg)
        if (r != null) return decodeURIComponent(r[2])
        return ''
    },
    //处理空格
    trim(str) {
        if (str) {
            str.replace(/(^\s*)|(\s*$)/g, '')
        } else {
            str = ''
        }
        return str
    },
    //数组判断
    isArrayFn(value) {
        if (typeof Array.isArray === 'function') {
            return Array.isArray(value)
        } else {
            return Object.prototype.toString.call(value) === '[object Array]'
        }
    },
    //转换图片
    tansPics(array, col, params = {}) {
        if (col) {
            let cols = col.indexOf('.') > -1 ? col.split('.') : [col]
            if (this.isArrayFn(array)) {
                for (let index = 0; index < array.length; index++) {
                    getValue(array[index], cols, params)
                }
            } else {
                getValue(array, cols, params)
            }
        } else {
            return getValue(array, '', params)
        }
    },

    gotoDetail(type, id, key = '') {
        let url = `/${type}-detail.html?id=${id}`;
        if (key) {
            url += `&key=${key}`;
        }
        window.open(url);
    },

    showLevelDialog(el, school = '',url) {
        el.$alert(
            `
             <div style="border-top:1px solid #EBEBEB;padding: 25px 10px;font-size: 14px;">
                <p>您将要离开"湖南大学生就业服务平台"，进入<span style="color:#1963D0">"${school??""}"</span>就业网站进行投递。</p>
                <p style="margin-top: 30px"><span style="color:#FA0000">*注：</span>跳转网站可能存在投递限制，请谨慎。</p>
            </div>`,
            '温馨提示', {
                dangerouslyUseHTMLString: true
            }).then(() => {
            if(url){
                window.open(url)
            }
        }).catch(action => {
            console.log('cancel', action);
        });
    },

    showApplyDialog(el, school = '',url) {
        el.$alert(
            `
             <div style="border-top:1px solid #EBEBEB;padding: 25px 10px;font-size: 14px;">
                <p>您将要离开"湖南大学生就业服务平台"，进入<span style="color:#1963D0">"${school}"</span>就业网站进行申请。</p>
                <p style="margin-top: 30px"><span style="color:#FA0000">*注：</span>跳转网站可能存在申请限制，请谨慎。</p>
            </div>`,
            '温馨提示', {
                dangerouslyUseHTMLString: true
            }).then(() => {
            if(url){
                window.open(url)
            }
        }).catch(action => {
            console.log('cancel', action);
        });
    },

    getDefaultImg() {
        let i = Math.round(Math.random() * 3);
        return '/imgs/default/sxh_0' + (i > 0 ? i : 1) + '.jpg'
    }

}

export default utils