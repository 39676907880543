import Vue from 'vue'
import '../sass/reset.scss'
import 'element-ui/lib/theme-chalk/index.css'
import utils from '../libs/utils'
import api from "../../api/api"
import "../sass/common.scss"
import ElementUI from 'element-ui';

Vue.use(ElementUI);

Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, '$util', {
    value: utils
})
Object.defineProperty(Vue.prototype, '$api', {
    value: api
})

Vue.directive('real-img', function (el, binding) {
    let imgURL = binding.value //获取图片地址
    let defaultURL = el.getAttribute('default-img') //获取默认图片地址
    if (imgURL) {
        let obj = {"img": imgURL}
        utils.tansPics(obj, "img");
        imgURL = obj.img;
        let a = function (url, callback) {
            let img = new Image()
            img.onload = function () {
                if (this.complete === true) {
                    typeof callback === 'function' ? callback(true) : null
                    img = null
                }
            }
            img.onerror = function () {
                typeof callback === 'function' ? callback(false) : null
                img = null
            }
            img.src = url
        }
        a(imgURL, function (exist) {
            if (exist) {
                el.setAttribute('src', imgURL)
            } else {
                if (defaultURL) {
                    el.setAttribute('src', defaultURL)
                }
            }
        })
    } else if (defaultURL) {
        el.setAttribute('src', defaultURL)
    }
})
