<template>
    <!--底部-->
    <footer class="gl-footer">
        <div class="footer-wrap">
            <div class="content-wrap footer-block">
                <p>指导单位：{{ setting.aboutUs }}
                    <span style="margin-left: 25px">主办单位：{{ setting.contcatUs }}</span>
                </p>
                <p>客服电话：{{ setting.copyright }}</p>
            </div>
        </div>
    </footer>
</template>

<script>
import tool from '@/utils/tool'
export default {
    name: "mFooter",
    data() {
        return {
            setting: {
                name:'智慧服务平台',
                aboutUs: '湖南省大中专学校学生信息咨询与就业指导中心',
                contcatUs: '湖南省长沙市东二环2段238号',
                copyright: '湖南省教育厅信息中心',
                switch:true,//网站开关
                closeRemark:'',//关闭说明
            }
        }
    },
    mounted() {

    },
    async created() {
        this.setting = tool.data.get('setting');
        if (!this.setting) {
            var res = await this.$api.getSetting();
            tool.data.set('setting', res);
            this.setting=res;
        }
        if(this.setting&&this.setting.switch!=undefined&&!this.setting.switch){//网站关闭
            alert(this.setting.closeRemark)
        }
        document.title=this.setting.name;
    }
}
</script>

<style scoped>

</style>