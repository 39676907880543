<template>
    <div class="career-item" :style="mStyle" @click="clickHandle">
        <div class="career-date-wrap">
            <p class="day">{{dateFormat(item.meetDate,'DD')}}</p>
            <p class="year">{{dateFormat(item.meetDate,'YYYY-MM')}}</p>
        </div>
        <div class="career-content">
            <div class="text-block">
                <p class="main-tit">{{item.title}}</p>
                <!-- <p class="sub-tit">举办地点：{{item.place}}</p> -->
                <p class="sub-tit">来源：{{item.schoolName}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "blockCareer",
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            mStyle: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        methods: {
            dateFormat(date,format){
                return moment(date).format(format)
            },
            clickHandle() {
                this.$emit('click')
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/common/sass/common.scss";

    .career-item {
        width: 450px;
        margin: 10px 25px 10px 0;
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        padding: 22px 15px;


        &:nth-of-type(3),
        &:nth-of-type(6),
        &:nth-of-type(9),
        &:nth-of-type(12) {
            margin-right: 0;
        }

        .career-date-wrap {
            width: 80px;
            position: relative;

            .day {
                font-size: 30px;
                color: $mainColor;
                line-height: 40px;
            }

            .year {
                font-size: 14px;
                color: #999999;
                line-height: 20px;
            }

            &:after {
                position: absolute;
                content: '';
                width: 2px;
                height: 40px;
                background: #F3F3F3;
                right: 0;
                top: 0;
            }
        }

        .career-content {
            width: calc(100% - 106px);
            padding: 0 25px;
            overflow: hidden;
            display: flex;

            .text-block {
                padding: 0 5px;

                .main-tit {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 30px;
                    width: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }

                .sub-tit {
                    margin-top: 10px;
                    font-size: 14px;
                    color: #888888;
                }
            }

            &:hover {
                .main-tit {
                    color: $mainColor;
                }
            }
        }
    }
</style>