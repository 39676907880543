<template>
    <div class="title-col">
        <div class="title-txt" :style="mStyle.titleStyle">
            {{headerList.title}}
            <div class="under-line" :style="mStyle.lineStyle"></div>
        </div>
        <div class="tag-list" v-if="headerList.tagList">
            <div class="tag-block"
                 v-for="(item,i) in headerList.tagList"
                 :key="item.key"
                 :class="{'active':currIndex === i}"
                 @click="changeIndex(i,item)">
                {{item.title}}
            </div>
        </div>
        <div class="more" @click="goMore" v-if="headerList.more">
            查看更多 &gt;
        </div>
    </div>
</template>

<script>
    export default {
        name: "headerList",
        data() {
            return {
                currIndex: ''
            }
        },
        props: {
            headerList: {
                type: Object,
                default() {
                    return {
                        title: '',
                        tagList: [],
                        more: true
                    }
                }
            },
            mStyle: {
                type: Object,
                default() {
                    return {
                        titleStyle: {},
                        lineStyle: {}
                    }
                }
            }
        },
        created() {
            this.currIndex = this.headerList.tagIndex;
        },
        methods: {
            changeIndex(i, item) {
                if(!item.link) {
                    this.currIndex = i;
                    this.$emit('change', i, item);
                }else{
                    window.open(item.link);
                }
            },
            goMore() {
                let item = this.headerList.tagList[this.currIndex];
                if(!item.url) {
                    this.$emit('more', item);
                }else{
                    window.open(item.url);
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/common/sass/common.scss";

    .title-col {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 45px;

        .title-txt {
            width: 130px;
            height: 31px;
            font-size: 30px;
            font-weight: 400;
            color: #252525;
            position: absolute;
            text-align: center;
            top: -5px;
            z-index: 2;

            .under-line {
                position: absolute;
                z-index: -1;
                left: 0;
                bottom: -12px;
                width: 100%;
                height: 15px;
                background: #E7EFFA;
            }
        }

        .tag-list {
            display: flex;
            margin-left: 146px;

            .tag-block {
                position: relative;
                width: 130px;
                height: 40px;
                background: #F3F3F3;
                border-radius: 5px;
                margin-left: 15px;
                font-size: 16px;
                font-weight: bold;
                color: #252525;
                line-height: 40px;
                text-align: center;
                cursor: pointer;

                &.active {
                    background-color: $mainColor;
                    color: #ffffff;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        margin-left: -5px;
                        width: 0px;
                        height: 0px;
                        border-top: 8px solid $mainColor;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        bottom: -8px;
                    }
                }

                &:not(.active):hover {
                    color: $mainColor
                }
            }
        }

        .more {
            font-size: 14px;
            font-weight: 400;
            color: #1963D0;
            cursor: pointer;
        }
    }
</style>