<template>
    <div class="jobfair-item" :style="mStyle" @click="clickHandle">
        <img class="block-img"
             v-real-img="item.posterHeaderUrl"
             :default-img="getDefault()"
             alt="">
        <p class="block-tit">
            【{{item.schoolName}}】{{item.title}}
            <span v-if="item.status===0" class="state state-success">报名中</span>
            <span v-if="item.status===1" class="state state-success">报名截止</span>
            <span v-if="item.status===2" class="state state-success">进行中</span>
            <span v-if="item.status===3" class="state state-warn">已结束</span>
        </p>
        <p class="block-tip">
            举办时间：
            <span class="block-time">{{dateFormat(item.meetTime,'YYYY-MM-DD HH:mm')}} ~ {{dateFormat(item.meetEndTime,'YYYY-MM-DD HH:mm')}}</span>
        </p>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "blockJobFair",
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            mStyle: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        methods: {
            dateFormat(date, format) {
                return moment(date).format(format);
            },
            clickHandle() {
                this.$emit('click')
            },
            getDefault() {
                return this.$util.getDefaultImg();
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/common/sass/common.scss";

    .jobfair-item {
        width: 330px;
        margin: 20px 25px 20px 0;
        cursor: pointer;

        &:nth-of-type(4),
        &:nth-of-type(8),
        &:nth-of-type(12) {
            margin-right: 0;
        }

        .block-img {
            width: 100%;
            height: 160px;
        }

        .block-tit {
            height: 55px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            margin-top: 18px;
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .block-tip {
            margin-top: 18px;
            height: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;

            .block-time {
                font-size: 13px;
                height: 10px;
                font-weight: 400;
                font-style: italic;
            }
        }

        &:hover {
            .block-tit, .block-tip {
                color: $mainColor;
            }
        }
    }
</style>