
const tool = {}

/* localStorage */
tool.data = {
	set(key, data, datetime = 0) {
		// //加密
		// if (sysConfig.LS_ENCRYPTION == "AES") {
		// 	data = tool.crypto.AES.encrypt(JSON.stringify(data), sysConfig.LS_ENCRYPTION_key)
		// }
		let cacheValue = {
			content: data,
			datetime: parseInt(datetime) === 0 ? 0 : new Date().getTime() + parseInt(datetime) * 1000
		}
		return localStorage.setItem(key, JSON.stringify(cacheValue))
	},
	get(key) {
		try {
			const value = JSON.parse(localStorage.getItem(key))
			if (value) {
				let nowTime = new Date().getTime()
				if (nowTime > value.datetime && value.datetime != 0) {
					localStorage.removeItem(key)
					return null;
				}
				// //解密
				// if (sysConfig.LS_ENCRYPTION == "AES") {
				// 	value.content = JSON.parse(tool.crypto.AES.decrypt(value.content, sysConfig.LS_ENCRYPTION_key))
				// }
				return value.content
			}
			return null
		} catch (err) {
			return null
		}
	},
	remove(key) {
		return localStorage.removeItem(key)
	},
	clear() {
		return localStorage.clear()
	}
}

export default tool